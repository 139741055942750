<template>
  <div class="sa">
    <div class="sa-error">
      <div class="sa-error-x">
        <div class="sa-error-left"></div>
        <div class="sa-error-right"></div>
      </div>
      <div class="sa-error-placeholder"></div>
      <div class="sa-error-fix"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>