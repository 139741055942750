<template>
  <div class="sa">
    <div class="sa-success">
      <div class="sa-success-tip"></div>
      <div class="sa-success-long"></div>
      <div class="sa-success-placeholder"></div>
      <div class="sa-success-fix"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>